import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

function OrderInvoice() {
  const { singleOrder } = useSelector((store) => store.orders);
  const [products, setProducts] = useState([]);
  const [orderinfo, setOrderinfo] = useState("");
  const [totlaAmount, setTotalAmount] = useState("");
  const [totalMrp, setTotalMrp] = useState("");

  const [orderid, setOrderid] = useState("");
  const [orderids, setOrderids] = useState("");
  const [packCount, setPackCount] = useState(0);
  const [saving, setSaving] = useState(0);
  const [couponDet, setcouponDet] = useState("");

  useEffect(() => {
    const orderdetails = singleOrder;
    setOrderinfo(orderdetails);
    setOrderids(orderdetails._id);
    setOrderid(orderdetails._id.slice(18));
    setSaving(orderdetails.Saving.toFixed(2));
    // setAddress(orderdetails.shippingInfo);
    setcouponDet(orderdetails.couponDetails);
    const filterProducts = orderdetails.OrderProducts.filter(
      (order) => order.Status === true
    );
    setProducts(filterProducts);
    // setDelivery(orderdetails.deliveryInfo);
    const totalAmt = filterProducts.reduce((sum, indOrder) => {
      return sum + indOrder.TotalAmount;
    }, 0);
    setTotalAmount(totalAmt);
    const totalMrp = filterProducts.reduce((sum, indOrder) => {
      return sum + indOrder.TotalMrp;
    }, 0);
    setTotalMrp(totalMrp);
    const prod = orderdetails.OrderProducts;
    let packCount = 0;

    for (let index = 0; index < prod.length; index++) {
      const element = prod[index];

      packCount = packCount + element.Qty;
      setPackCount(packCount);
    }
  }, [singleOrder]);

  console.log(orderinfo, "orderinfo");

  const taxable = Math.round((orderinfo.netPayable / 118) * 100);
  const gst = Math.round((orderinfo.netPayable / 118) * 18);
  const total = taxable + gst;

  return (
    <section className="theme-invoice-1">
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-8 mx-auto my-3">
            <div className="invoice-wrapper">
              <div className="invoice-header">
                <div className="header-image">
                  <img
                    src="assets/images/kw-invoice-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </div>
                {/* <div className="header-content">
                  <h3>Invoice</h3>
                </div> */}
              </div>
              <div className="invoice-body">
                <div className="top-sec">
                  <div className="row">
                    <div className="col-12">
                      <div className="details-box">
                        <div className="address-box">
                          <ul>
                            <li className=" theme-color liclass my-1">
                              Shanthi Retails
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "capitalize" }}
                            >
                              Jamshedpur
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "capitalize" }}
                            >
                              Mob: +91- 8877779770
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "capitalize" }}
                            >
                              Email: care@kiranaworld.in
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "capitalize" }}
                            >
                              GSTN:- 20DSYPM2888F1Z7
                            </li>
                          </ul>
                        </div>
                        <div className="address-box">
                          <ul>
                            <li className="theme-color my-1">INVOICE</li>
                            <li className="liclass">
                              Date:{" "}
                              {moment(orderinfo.updatedAt).format("DD-MM-YYYY")}
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "uppercase" }}
                            >
                              Order ID : {orderid}
                            </li>
                            <li
                              className="liclass"
                              style={{ textTransform: "uppercase" }}
                            >
                              Customer ID :33613
                            </li>
                            <li
                              className="liclass"
                              style={{ opacity: "0", visibility: "hidden" }}
                            >
                              Delivery Number :
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="top-sec ">
                  <div className="row my-3">
                    <div className="col-12 d-flex">
                      <div className="col-3">
                        <ul style={{ margin: 0, padding: 0 }}>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            Client Name
                          </li>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            Phone:-
                          </li>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            Address:-
                          </li>
                        </ul>
                      </div>
                      <div className="col-9">
                        <ul style={{ margin: 0, padding: 0 }}>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            {orderinfo.ClientName}
                          </li>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            Phone:- {orderinfo.Mobile}
                          </li>
                          <li style={{ listStyle: "none", fontWeight: "600" }}>
                            Address:- {orderinfo.Address}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="invoice-table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Invoice Date:</th>
                          <th>Invoice No:</th>

                          <th>Due Amount:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(orderinfo.updatedAt).format("MMM DD, YYYY")}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {orderid}
                          </td>

                          <td>₹{orderinfo.netPayable}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
                <div className="invoice-table-2">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th className="text-start">Item Name</th>
                          <th>PackSize</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((order, index) => (
                          <tr key={index}>
                            <td className="text-content">{index + 1}</td>
                            <td>{order.ItemName}</td>
                            <td style={{ maxWidth: "25px" }}>
                              {" "}
                              {order.PackSize}
                            </td>
                            <td> {order.Qty}</td>
                            <td>₹ {order.Price}</td>
                            <td>₹ {order.Qty * order.Price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end col-12">
                  <div className="d-flex justify-content-end col-6">
                    <div className="price-box col-6">
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li
                          className="liclad d-flex align-items-start "
                          style={{ textAlign: "start" }}
                        >
                          Payment Mode
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li
                          className="liclad d-flex align-items-start "
                          style={{ textAlign: "start" }}
                        >
                          Payment Status
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Product Count
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Pack Count
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Total MRP
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Coupon Title
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Coupon Description
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Cashback
                        </li>
                      </ul>
                    </div>
                    <div className="price-box col-6">
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start ">
                          {orderinfo.PaymentMode}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {orderinfo.hasOwnProperty("PaymentStatus") ? (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              {orderinfo.PaymentStatus}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              Na
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start">
                          {orderinfo.ProductCount}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start">
                          {packCount}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad  d-flex align-items-start">
                          {totalMrp}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {couponDet.hasOwnProperty("CouponTitle") ? (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              {couponDet.CouponTitle}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              Na
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {couponDet.hasOwnProperty("CouponDescription") ? (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              {couponDet.CouponDescription}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="theme-color liclad d-flex align-items-start ">
                              Na
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad  d-flex align-items-start">
                          {/* ₹{orderinfo.netPayable} */}
                          {orderinfo.Cashback}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end col-6">
                    <div className="price-box col-6">
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li
                          className="liclad d-flex align-items-start "
                          style={{ textAlign: "start" }}
                        >
                          SubTotal
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li
                          className="liclad d-flex align-items-start "
                          style={{ textAlign: "start" }}
                        >
                          Delivery Charge
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Coupon Discount
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Saving
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Total Amount
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Wallet Used
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="liclad d-flex align-items-start">
                          Net Payble
                        </li>
                      </ul>
                    </div>
                    <div className="price-box col-6">
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start ">
                          {/* {totlaAmount +
                            couponDet.couponDis -
                            orderinfo.DeliveryCharge
                          } */}
                          {totlaAmount}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start ">
                          {totlaAmount > 499 ? 0 : 40}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start">
                          {couponDet.couponDis}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad d-flex align-items-start">
                          {saving}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad  d-flex align-items-start">
                          {totlaAmount > 499 ? totlaAmount : totlaAmount + 40}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad  d-flex align-items-start">
                          {orderinfo.wallet}
                        </li>
                      </ul>
                      <ul
                        className="ulclasss"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <li className="theme-color liclad  d-flex align-items-start">
                          {/* {totlaAmount > 499
                            ? totlaAmount - orderinfo.wallet
                            : totlaAmount + 40 - orderinfo.wallet} */}
                          {totlaAmount > 499
                            ? totlaAmount -
                              orderinfo.wallet -
                              couponDet.couponDis
                            : totlaAmount +
                              40 -
                              orderinfo.wallet -
                              couponDet.couponDis}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-sec ">
                <div className="row my-3">
                  <div className="col-12 ">
                    <p>
                      <span style={{ fontWeight: "700" }}>Note:</span>
                      We request our valuable customers to check the products at
                      the time of delivery. For complaint/refund, if any,
                      Whatsapp @8877778770 or 8877779770. All Price are
                      inclusive of GST as applicable.
                    </p>
                  </div>
                </div>
              </div>

              <div className="invoice-footer">
                <div className="signature-box">
                  {/* <img src="assets/images/sign.png" className="img-fluid" alt />
                  <h5>Authorised Sign</h5> */}
                </div>
                <div className="button-group">
                  <ul>
                    <li className="liclass">
                      <button
                        className="btn theme-bg-color text-white rounded"
                        onClick={() => window.print()}
                      >
                        Print
                      </button>
                    </li>
                    {/* <li className="liclass">
                      <button
                        className="btn text-white print-button rounded ms-2"
                        onClick={() => window.print()}
                      >
                         Export As PDF
                      </button>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrderInvoice;
